/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

// core components
import {
  version
} from "../../variables/version.js";


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © 2020{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://gruposaite.com.br/"
                target="_blank"
              >Grupo Saite{" "}
              </a>
              Todos os direitos reservados. {version}
            </div>
          </Col>
          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <h4 className="support">Precisa de ajuda?</h4>
              </NavItem>
              <NavItem>
                <NavLink
                  href="mailto:contato@gruposaite.com.br"
                  target="_blank"
                >
                  Fale conosco
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
      </footer>
    );
  }
}

export default Footer;
