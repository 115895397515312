/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';
// react component that copies the given text inside your clipboard
//import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Label,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import CharCountInput from "components/Inputs/CharCountInput.js";

class EditQuestion extends React.Component {

  constructor(){
    super()
    this.state = {
      pergunta : '',
      habilidade : "Conhecimento",
      dica : '',
      alternativaA : '',
      alternativaB : '',
      alternativaC : '',
      alternativaD : '',
      feedbackA : '',
      feedbackB : '',
      feedbackC : '',
      feedbackD: '',
      campo : '',
      correta: ''
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e){
    e.preventDefault()
    const questionario = localStorage.getItem('questionarioID')
    const questionID = localStorage.getItem('questionID')
    
    const question = {
      pergunta : this.state.pergunta,
      habilidade : this.state.habilidade,
      dica : this.state.dica,
      alternativas : [this.state.alternativaA,this.state.alternativaB,this.state.alternativaC,this.state.alternativaD],
      feedback : [this.state.feedbackA,this.state.feedbackB,this.state.feedbackC,this.state.feedbackD],
      dificuldade : parseInt(this.state.campo), 
      correta: parseInt(this.state.correta),
      tempo : 30
    }
    api.put('questions/question/'+questionario+'/'+ questionID, question)
      .then(res => {
        alert("Edição concluída")
        this.props.history.push('/admin/listQuestions')
      })
      .catch(err => {
        alert("Erro ao cadastrar nova questão")
      })
  }

  componentDidMount(){
    if (localStorage.usertoken){
        try {
          //verificar autenticação
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
       
    const questionario = localStorage.getItem('questionarioID')
    const questionID = localStorage.getItem('questionID')
    api.get('questions/questionnaires/'+ questionario+'/'+questionID)
      .then(
        res => {
          //console.log(res.data)
          return res.data
        })
      .then(questao => {
        //console.log(questao.question)
        this.setState({
          pergunta : questao.question.pergunta ,
          habilidade : questao.question.habilidade,
          dica : questao.question.dica,
          alternativaA : questao.question.alternativas[0],
          alternativaB : questao.question.alternativas[1],
          alternativaC : questao.question.alternativas[2],
          alternativaD :questao.question.alternativas[3],
          feedbackA : questao.question.feedback[0],
          feedbackB : questao.question.feedback[1],
          feedbackC : questao.question.feedback[2],
          feedbackD: questao.question.feedback[3], 
          correta: questao.question.correta,
          campo : questao.question.dificuldade
        })
        //console.log(this.state)
        //console.log('aqui ->',questao)
        //console.log(questionario)
      })
      .catch(err => {
        alert("Erro ao carregar os dados da questão", err)
      })

      // api.get('testeAPI/Data/'+ questionario)
      //   .then(res => {
      //       return res.data
      //   })
      //   .then( questao => {
      //     for(let i in questao.etapas){
      //       for(let j in questao.etapas[i].questions){
      //         if(id === questao.etapas[i].questions[j]._id){
      //           this.setState({
      //             campo: parseInt(i)
      //           })
      //           console.log("campo ->",i)
      //         }
      //       }
      //     } 
      //   })
      //   .catch(err => {
      //     alert("Erro ao carregar os dados do campo", err)
      //   })
  }
  getHtmlTooltips(){
    let saida = [];    
    let hashAlternativas = ["A","B","C","D"];
    for (const [index, value] of hashAlternativas.entries()) {
      saida.push(
        <div key={"Alternativa"+index}>
          <UncontrolledTooltip
            delay={0}
            placement="bottom"
            target={"FieldAlternativa"+value}
          >
            Alternativa {value}
          </UncontrolledTooltip>
        </div>
      )
      saida.push(
        <div key={"Feedback"+index}>
          <UncontrolledTooltip
            delay={0}
            placement="bottom"
            target={"FieldFeedback"+value}
          >
            Feedback {value}{<br/>}(Resposta dada ao aluno após ele selecionar a alternativa {value})
          </UncontrolledTooltip>
        </div>
      )
    }
    saida.push(
    <div key="Tooltip1">
      <UncontrolledTooltip
        delay={0}
        placement="bottom"
        target="FieldPergunta"
      >
        Pergunta que deve ser respondida pelo aluno
      </UncontrolledTooltip>
      <UncontrolledTooltip
        delay={0}
        placement="bottom"
        target="FieldDica"
      >
        Dica para ajudar o aluno (não pode ser a resposta)
      </UncontrolledTooltip>
      <UncontrolledTooltip
        delay={0}
        placement="top"
        target="FieldCorreta"
      >
        Alternativa correta
      </UncontrolledTooltip>
      <UncontrolledTooltip
        delay={0}
        placement="top"
        target="FieldDificuldade"
      >
        Dificuldade imposta pelo professor à questão
      </UncontrolledTooltip>
    </div>
    )
    //console.log(saida);
    return saida;
  }
  onKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  render() {
    const tooltips = this.getHtmlTooltips();
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          
          <Card className="bg-light shadow">
                <CardHeader className="bg-light border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Questão</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form noValidate  onKeyPress={this.onKeyPress} onSubmit = {this.onSubmit} id = "fase1-form">
                    <h6 className="heading-small text-muted mb-4">
                      Informações
                    </h6>
                    <div className="pl-lg-4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Pergunta
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="323"
                              minLength="27"
                              rows="3"
                              id="FieldPergunta"
                              type="textarea" name = "pergunta" placeholder = "pergunta"
                            value = {this.state.pergunta} onChange = {this.onChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Dica
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="250"
                              minLength="27"
                              rows="2"
                              id="FieldDica"
                              type="textarea" name = "dica" placeholder = "Dica"
                            value = {this.state.dica} onChange = {this.onChange}
                            />
                          </FormGroup>
                          {/*<FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Habilidade
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              defaultValue="Conhecimento"
                              type="text" name = "habilidade" 

                            placeholder = "Habilidade" value = {this.state.habilidade} 
                            onChange = {this.onChange}
                            />
                          </FormGroup>*/}
                    </div>
                    <hr className="my-4" />
                    {/* Alternativas */}
                    <h6 className="heading-small text-muted mb-4">
                      Alternativas
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Alternativa A
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="140"
                              minLength="27"
                              name = "alternativaA" 
                              id="FieldAlternativaA"
                            placeholder = "Alternativa A" value = {this.state.alternativaA} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Feedback A
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              // maxLength="150"
                              minLength="27"
                              name = "feedbackA"
                              id="FieldFeedbackA"
                            placeholder = "feedback A" value = {this.state.feedbackA} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Alternativa B
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="140"
                              minLength="27"
                              name = "alternativaB" 
                              id="FieldAlternativaB"
                            placeholder = "Alternativa B" value = {this.state.alternativaB} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"

                            >
                              Feedback B
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              // maxLength="150"
                              minLength="27"
                              name = "feedbackB" 
                              id="FieldFeedbackB"
                            placeholder = "feedback B" value = {this.state.feedbackB} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"

                            >
                              Alternativa C
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="140"
                              minLength="27"
                              name = "alternativaC" 
                              id="FieldAlternativaC"
                            placeholder = "Alternativa C" value = {this.state.alternativaC} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"

                            >
                              Feedback C
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              // maxLength="150"
                              minLength="27"
                              name = "feedbackC" 
                              id="FieldFeedbackC"
                            placeholder = "feedback C" value = {this.state.feedbackC} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"

                            >
                              Alternativa D
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              maxLength="140"
                              minLength="27"
                              name = "alternativaD" 
                              id="FieldAlternativaD"
                            placeholder = "Alternativa D" value = {this.state.alternativaD} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"

                            >
                              Feedback D
                            </label>
                            <CharCountInput
                              className="form-control-alternative"
                              // maxLength="150"
                              minLength="27"
                              name = "feedbackD" 
                              id="FieldFeedbackD"
                            placeholder = "feedback D" value = {this.state.feedbackD} 
                            onChange = {this.onChange}
                            rows="2"
                            type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Informações técnicas */}
                    {/* <h6 className="heading-small text-muted mb-4">About me</h6>*/}
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="exampleSelect">Alternativa Correta</Label>
                            <Input type="select"   name = "correta"  value={this.state.correta}
                              id="FieldCorreta"
                              onChange = {this.onChange}>
                              <option value = "0">A</option>
                              <option value = "1">B</option>
                              <option value = "2">C</option>
                              <option value = "3">D</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="exampleSelect">Dificuldade</Label>
                            <Input type="select" name = "campo" value={this.state.campo}
                              id="FieldDificuldade"
                               onChange = {this.onChange}>
                              <option value = "1">Fácil</option>
                              <option value = "2">Médio</option>
                              <option value = "3">Difícil</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Salvar
                      </Button>
                      <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-danger cosmo-color-1">
                        Voltar
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
        </Container>
        {tooltips}
      </>
    );
  }
}

export default EditQuestion;
