/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';
// react component that copies the given text inside your clipboard
//import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  //Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import CharCountInput from "components/Inputs/CharCountInput.js";


class RegisterQuestionnaire extends React.Component {
  constructor(){
  super()
  this.state = {
    tema:'',
    informacao:'',
    loading: true
  }
  
  this.onChange = this.onChange.bind(this)
  this.onSubmit = this.onSubmit.bind(this)
  }
  
  componentDidMount(){
    if (localStorage.usertoken){
        try {
          //verificar autenticação
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
  }

  onChange(e){  
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e){
    e.preventDefault()

    const tema = {
      name: this.state.tema,
      information: this.state.informacao,
      image: "IconeSelec1"
    }

    api.post('questions/questionnaires', tema)
      .then( res => {
        if(res === 'Arquivo já existe'){
          alert("Arquivo já existe, informe outro nome")
        }       
        else{
          this.props.history.push('/admin/questionnaires')
          //console.log(res.data)
        }
      })
      .catch(err => {
        alert("Erro ao cadastrar novo questionário")
      })
  }
  
  getHtmlTooltips(){
    let saida = 
    <div>
    <UncontrolledTooltip
      delay={0}
      placement="bottom"
      target="InputName"
    >
      Nome do Quiz
    </UncontrolledTooltip>
      <UncontrolledTooltip
        delay={0}
        placement="bottom"
        target="InputInfo"
      >
        Informação sobre o Quiz, mostrada ao usuário dentro do jogo
      </UncontrolledTooltip>
    </div>
    return saida;
  }
  onKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  render() {
    const tooltips = this.getHtmlTooltips();
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {tooltips}
          {/* Table */}
          
              <Card className="bg-secondary shadow">
                <CardHeader className=" border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Novo Questionário</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form noValidate onKeyPress={this.onKeyPress} onSubmit = {this.onSubmit} id = "fase1-form">
                    <h6 className="heading-small text-muted mb-4">
                      Informações
                    </h6>
                    <div className="pl-lg-4">
                          <FormGroup>
                            <Label for="name" className=" mb-2">Nome</Label>
                            <CharCountInput
                              id="InputName"
                              className="form-control-alternative"
                              type="text" name = "tema"
                              // maxLength="20"
                            value = {this.state.tema} onChange = {this.onChange}
                            placeholder={"nome do questionário"}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="information" className=" mb-2">Informação</Label>
                            <CharCountInput
                              id="InputInfo"
                              className="form-control-alternative"
                              rows="2"
                              type="textarea" name = "informacao"
                              // maxLength="150"
                            value = {this.state.informacao} onChange = {this.onChange}
                            placeholder={"Quiz direcioado a quem está estudando sobre ..."}
                            />
                          </FormGroup>
                    </div>
                    <hr className="my-4" />
                    
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Criar
                      </Button>
                      <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-danger cosmo-color-1">
                        Voltar
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
        </Container>
      </>
    );
  }
}

export default RegisterQuestionnaire;
