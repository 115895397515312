/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import GoogleLogin from "react-google-login";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row, 
  // Modal, 
  // ModalHeader, 
  // ModalBody, 
  // ModalFooter,
  //CardBody,
  //FormGroup,
  //Form,
  //Input,
  //InputGroupAddon,
  //InputGroupText,
  //InputGroup,
  Col
} from "reactstrap";

import api from 'services/Api'
// import modalErro from '../../components/Modals/ModalError'


class Login extends Component {
  constructor(){
    super()
    this.state = {
      email: '',
      senha: '',
      isLogged: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e){
    e.preventDefault()

    var user = {
      email: this.state.email,
      password: this.state.senha
    }

    api.post('auth/authenticate', user)
    .then(res => {
      localStorage.setItem('usertoken', res.data.token)
      localStorage.setItem('userName',res.data.user.name)
      localStorage.setItem('userID',res.data.user._id)
      localStorage.setItem('userImage',res.data.user.image)
      api.defaults.headers.common['Authorization'] = "Bearer "+res.data.token;
      this.props.history.push('/admin/inicio')
    })
    .catch(err => {
      alert('Erro ao realizar o login ')
      this.props.history.push('/auth/login')
    })
  }

  componentDidMount(){
    if (localStorage.usertoken){
        try {
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
  
  }
  responseGoogle = (response) => {
    
    var user = {
      email: response.email,
      tokenId: response.tokenId
    }
    //tentar fazer login com esse token_id
    api.post('auth/GoogleAuthenticate', user)
    .then(res => {

      localStorage.setItem('usertoken', res.data.token)
      localStorage.setItem('userName',res.data.user.name)
      localStorage.setItem('userID',res.data.user._id)
      localStorage.setItem('userImage',res.data.user.image)
      localStorage.setItem('userAcessLevel',res.data.user.AcessLevel)

      api.defaults.headers.common['Authorization'] = "Bearer "+res.data.token;
      this.props.history.push('/admin/inicio')
    })
    .catch(err => {
      alert('Erro ao realizar o login com google')
      // 
    })
  }
  responseGoogleERROR = (response) => {
    //console.log("erro com api do react google")
    //console.log(response)
    alert('Erro ao realizar o login com google')
  }


  onKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }
  render() {
    return (
      <>
        {/*<modalErro buttonLabel='open modal 2' />*/}
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white">GameQuiz!</h1>
                  </Col>
                </Row>
              </div>
            </Container>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                {/*<Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href=""
                  onClick={e => e.preventDefault()}
                  disabled="true"
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>*/}
                <GoogleLogin
                  clientId="205608985199-4pi1dog5kkr11voi7r6oska9b3igl5n8.apps.googleusercontent.com"
                  render={renderProps => (
                    <Button
                          className="btn-neutral btn-icon"
                          color="default"
                          href=""
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <span className="btn-inner--icon">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                    </Button>
                  )}
                  buttonText = "Login"
                  
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogleERROR}

                  cookiePolicy={'single_host_origin'}
                ></GoogleLogin>
              </div>
            </CardHeader>
            {/*
            <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
            </div>
              <Form  onKeyPress={this.onKeyPress}  role="form" onSubmit = {this.onSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" name = "email" autoComplete="new-email" onChange = {this.onChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" name = "senha" autoComplete="new-password" onChange = {this.onChange}/>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          */}
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
