/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';

// reactstrap components
import {
  Card,
  //Spinner,
  Form,
  FormGroup,
  Label,
  //Input,
  Button,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Media,
  Row
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import CharCountInput from "components/Inputs/CharCountInput.js";

class ListUsers extends React.Component {

  constructor() {
    super()
    this.state = {
      users: [],
      email : undefined
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

  }

  componentDidMount() {
    if (localStorage.usertoken){
        try {
          //verificar autenticação
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
    
    
    api.get('users/allUsers')
      .then(res => {
        const users = res.data.allusers
        //console.log(users)
        this.setState({ users: users })
      })
      .catch(err => {
        alert("Erro ao carregar usuários")
        this.props.history.goBack()
      })
  }
  onChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e){
    e.preventDefault()

    api.post('/users/addUser', { email : this.state.email, userId : this.localStorage.userID})
      .then(res => {
        alert("Usuário adicionado")
        
      })
      .catch(err => {
        alert("Erro ao adicionar email")
      })
  }


  getHtmlUsers() {
    const users = this.state.users;
    let lista = [];
    for (let i in users) {
        lista.push(

                    <tr key={i} >
                      <td >{i}</td>
                      <td >
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          
                          <img
                            alt=""
                            //src={require("../../assets/img/theme/GameQuiz-Icone.png")}
                            src={users[i].image}
                          />
                        </span>
                      </Media>
                      </td>
                      <td >{users[i].name}</td>
                      <td >{users[i].email}</td>
                      <td>{users[i].createdAt}</td>
                      <td>{users[i].AcessLevel}</td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href=""
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href=""
                              onClick={e=>e.preventDefault()}
                            >
                              Editar
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>

        )
       }

      // if(lista.length<1){
      //   lista.push(
      //     <Spinner color="info" key="spinner"/>
      //   );
      // }
    return lista
  }
  onKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  render() {
    const users = this.getHtmlUsers()
    console.log(process.env);
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Dark table */}
          <Row className="mt-5">

            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-2">Usuários</h3>
                </CardHeader>
                 {/*
                <div> DIV informações do quesionário disponíveis pra edição (nome e informações) criar form</div>
                 */}
                <Card className="bg-default shadow">
                  <CardHeader className="bg-transparent border-0">
                  <Form  onKeyPress={this.onKeyPress}  noValidate onSubmit = {this.onSubmit}>
                    <FormGroup>
                      <Label for="email" className="text-white mb-2">Email</Label>
                      <CharCountInput value = {this.state.email} 
                            onChange = {this.onChange} type="text" name="email" id="email" placeholder={"example@gmail.com"}/>
                    </FormGroup>  
                    
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Cadastrar Gmail
                      </Button>
                      <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-danger cosmo-color-1">
                        Voltar
                      </button>
                    </div>
                  </Form> 
                  </CardHeader>
                </Card>
                <CardHeader className="bg-transparent border-0">
                            {/*
                            <Button  className="mr-4" color="info" href="" onClick = {e=>e.preventDefault()} size="sm">
                              Criar nova Questão
                            </Button>
                            */}
                </CardHeader>
                <Table bordered hover
                  className="align-items-center table-dark table-flush Table"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" />
                      <th scope="col">Nome</th>
                      <th scope="col">Email</th>
                      <th scope="col">Data de criação</th>
                      <th scope="col">AcessLevel</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {users}
                  </tbody>
                </Table>

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ListUsers;
