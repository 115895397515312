/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';


// reactstrap components
import {
  Jumbotron,
  // Modal,
  // Form,
  // FormGroup,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  // Alert,
  Button,
  Card, 
  CardText, 
  // CardHeader,
  CardFooter,
  // Spinner,
  CardBody,
  CardTitle, 
  Container,
  Col,
  Row,
  UncontrolledAlert ,
  //UncontrolledTooltip,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

// import {CopyToClipboard} from 'react-copy-to-clipboard';

class Trash extends React.Component {
  state = {};
  constructor(){

    super()
    this.state = {
      questionarios : [],
      tema: [],
      jsonName: [],
      switch1: true,
      defaultModal: false
    }
    
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  

  componentDidMount(){
    if (localStorage.usertoken){
        try {
          //verificar autenticação
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
     api.get('questions/deleted')
      .then(res => {
        const questionarios = res.data
        this.setState({questionarios: questionarios.questionnaires})

      })
      .catch(err => {
        alert("Erro ao carregar questionários");
      })
  }

  deleteQuestionnaire(questionnaireId){

    api.post('questions/questionnaires/deleted/'+questionnaireId,{deleted:false})
      .then(res => {
        window.location.reload()
      })
      .catch(err => {
        alert("Erro ao tentar arquivar o questionário");

      })
  }

  
  CopyCode(){
    alert("Código copiado com sucesso")
  } 
  
  //criar um questionário
  goCreate(e){
    e.preventDefault()
    this.props.history.push('/admin/registerQuestionnaire')
  }

  //editar questões 
  goEdit(e, temaId, temaName){
    e.preventDefault()
    localStorage.setItem('questionarioID', temaId);
    //console.log(temaId)
    localStorage.setItem('questionarioName', temaName);
    this.props.history.push('/admin/listQuestions')
  } 

  getHtmlQuestionarios(){
    const questionarios = this.state.questionarios;
    //const jsonName = this.state.jsonName;
    let lista = [];
    // var haveone = false;
    for(let i in questionarios){
        var edited = new Date(questionarios[i].editedAt);
      var date2_ms = Date.now();
      var date1_ms = Date.parse(edited);

      // Calculate the difference in milliseconds
      var difference_ms = date2_ms - date1_ms;
      //take out milliseconds
      difference_ms = difference_ms/1000;
      var seconds = Math.floor(difference_ms % 60);
      difference_ms = difference_ms/60; 
      var minutes = Math.floor(difference_ms % 60);
      difference_ms = difference_ms/60; 
      var hours = Math.floor(difference_ms % 24);  
      var days = Math.floor(difference_ms/24);
      
      var displayDate = "";
      if(days>0)displayDate+=days + ' dias, ';
      if(hours>0||days>0)displayDate+=hours + ' horas, ';
      displayDate+= minutes + ' minutos, e ' + seconds + ' segundos';
      // haveone = true;
      lista.push(
        <div className = "col-lg-6"  key = {questionarios[i]._id}>
           
              <Card color="secondary">
                <CardBody>
                  <CardTitle>{questionarios[i].name}</CardTitle>
                  <CardText>{questionarios[i].information}</CardText>
                  <CardText>Excluído a {displayDate}</CardText>
                  <CardFooter>
                    <Button color="danger" href="" onClick={e=> this.deleteQuestionnaire(questionarios[i]._id)} size="sm">
                        <span className="btn-inner--icon">
                        <i className="far fa-trash-alt"></i>
                        </span>
                        RESTAURAR
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
          </div>

                      
      )
    }
    
    // if(lista.length<1&&(!haveone)){
    //   lista.push(
    //     <Spinner color="info" />
    //   );
    // }
    return lista
  }

  
  getHtmlTooltips(){
    let saida = 
    <div>
      {/* <UncontrolledTooltip
        delay={0}
        placement="bottom"
        target="BTNAddQuestionnaire"
      >
        Adiciona um Novo Questionário
      </UncontrolledTooltip> */}
    </div>
    return saida;
  }



  render() {
    const tooltips = this.getHtmlTooltips();
    return (
      <>
        <Header />
        {/* Page content */}
        <div>
          {tooltips}
          <Container className=" mt--7" fluid>
            {/* Table */}
            <Row>
              <Col>
                <Jumbotron className="bg-gradient-default" fluid >
                  <Container fluid>
                    <h1 className="display-3 text-white mb-3">Lixeira</h1>
                    <p className="lead text-white mb-3">Nessa página você visualiza todos os questionários aos quais você participa que estão na lixeira.
                    </p>
                  </Container>
                </Jumbotron>
                
              </Col>
            </Row>
                <UncontrolledAlert color="danger" fade={false}>
                    <span className="alert-inner--icon">
                    <i className="fas fa-exclamation-triangle"></i>
                    </span>{" "}
                    
                    <span className="alert-inner--text">
                      <strong>Alerta!</strong> Um questionário deletado ficará disponível para restauração somente até 30 dias após ser apagado.
                    </span>
                  </UncontrolledAlert>
            <Row>
              {this.getHtmlQuestionarios()}
            </Row>
            
          </Container>
        </div>
      </>
    );
  }
}

export default Trash;
