/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { 
  // BrowserRouter, 
  Route, 
  Switch, 
  Redirect, 
  HashRouter  
} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-dashboard-react.css";
import "assets/css/custom.css";
/*
/auth/index
/admin/questionnaires
/auth/codeQuestionnaires
/admin/listUsers


*/

//import './App.css';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";


//   var style1 = [
//     'background: linear-gradient(#D33106, #571402)'
//     , 'border: 1px solid #3E0E02'
//     , 'color: white'
//     , 'display: block'
//     , 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)'
//     , 'box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset'
//     , 'line-height: 40px'
//     , 'text-align: center'
//     , 'font-weight: bold'
// ].join(';');
var style2 = "font-family:helvetica; font-size:20px; font-size:50px; font-weight:bold; color:#e3aa08; -webkit-text-stroke:1px black;"
  
console.log('%c ATENÇÃO', style2);
console.log('O uso deste console talvez permita que invasores falsifiquem sua identidade e roubem informações por meio de um ataque chamado Self-XSS.Não digite nem cole códigos que você não conhece.'); 
console.log('Essa janela é destinada ao desenvolvimento do painel, nenhum código ou texto inserido aqui será enviado a aplicação principal, portanto se estiver tendo algum problema ou dúvida entre em contato com os desenvolvedores.');

ReactDOM.render(
<HashRouter>
  <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      {/*<Route exact={true} path="/about" component={About} />*/}
      {/*<Redirect from="*" to="/" />*/}
      <Redirect from="/" to="/auth/index" />
  </Switch>
</HashRouter>


,
  document.getElementById("root")
);
