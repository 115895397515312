/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';
// react component that copies the given text inside your clipboard
//import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Jumbotron,
  // Modal,
  // Form,
  // FormGroup,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  // Alert,
  Button,
  Card, 
  CardText, 
  // CardHeader,
  CardFooter,
  // Spinner,
  CardBody,
  CardTitle, 
  Container,
  Col,
  Row,
  UncontrolledAlert ,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import {CopyToClipboard} from 'react-copy-to-clipboard';

/*
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
*/

class Questionnaires extends React.Component {
  state = {};
  constructor(){

    super()
    this.state = {
      questionarios : [],
      tema: [],
      jsonName: [],
      switch1: true,
      defaultModal: false
    }
    
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  

  handleSwitchChange = nr => () => {
    let switchNumber = `switch${nr}`;
    this.setState({
      [switchNumber]: !this.state[switchNumber]
    });
  }

  componentDidMount(){
    if (localStorage.usertoken){
        try {
          //verificar autenticação
          api.defaults.headers.common['Authorization'] = "Bearer "+localStorage.usertoken;
          api.post('auth/')
          .then(res => {
            //autorizado
          })
          .catch(err => {
            localStorage.clear();
            this.props.history.push('/auth/login')
          })
        } catch (err) {
          localStorage.clear();
          this.props.history.push('/auth/login');
          alert("Logout...")
        }
    }
     api.get('questions/questionnaires')
      .then(res => {
        //console.log(res.data);
        const questionarios = res.data
        //console.log(questionarios);
        this.setState({questionarios: questionarios.questionnaires})
        //console.log(this.state.questionarios)
        //this.setState({tema: questionarios.temas})
        //this.setState({jsonName: questionarios.jsonName})
      })
      .catch(err => {
        alert("Erro ao carregar questionários");
        //console.log("err");
        //console.log(err);
        //if(err.status === 401 ){
          //problema na autenticação 
          //refazer autenticação (login)
          // localStorage.setItem('usertoken', undefined)
          // localStorage.setItem('userName',undefined)
          // localStorage.setItem('userID',undefined)
          // this.props.history.push('/')
        //}
      })
  }
  publishQuestionnaire(questionnaireId, disponibilidade){

    api.post('questions/questionnaires/status/'+questionnaireId,{available:disponibilidade})
      .then(res => {
        //console.log("sucesso ao trocar status de publicação do questionario" + questionnaireId);
        //console.log(res.data);
        window.location.reload()
        //

      })
      .catch(err => {
        alert("Erro ao tentar trocar o status de publicação o questionário");

        //++++++++++++++ COLOCAR CAIXA MOSTRANDO OS PRÉ REQUISITOS ???????????
        //console.log("err");
        //console.log(err);
      })
  }
  archiveQuestionnaire(questionnaireId){

    api.post('questions/questionnaires/archived/'+questionnaireId,{archived:true})
      .then(res => {
        window.location.reload()
      })
      .catch(err => {
        alert("Erro ao tentar arquivar o questionário");

      })
  }

  
  CopyCode(){
    alert("Código copiado com sucesso")
  } 
  
  //criar um questionário
  goCreate(e){
    e.preventDefault()
    this.props.history.push('/admin/registerQuestionnaire')
  }

  //editar questões 
  goEdit(e, temaId, temaName){
    e.preventDefault()
    localStorage.setItem('questionarioID', temaId);
    //console.log(temaId)
    localStorage.setItem('questionarioName', temaName);
    this.props.history.push('/admin/listQuestions')
  } 

  getHtmlQuestionarios(){
    const questionarios = this.state.questionarios;
    //const jsonName = this.state.jsonName;
    let lista = [];
    // var haveone = false;
    for(let i in questionarios){
      //console.log(questionarios[i]);
      // haveone = true;
      lista.push(
        <div className = "col-lg-6" style={{ padding: '15px' }}  key = {questionarios[i]._id}>
           
              <Card color="secondary">
                <CardBody>
                  <CardTitle>{questionarios[i].name}</CardTitle>
                  <CardText>{questionarios[i].information}</CardText>
                  <CardFooter>{
                  //if questionarios[i].available == true
                  (questionarios[i].available)
                  ?
                  (
                    <Container>
                      <Row>
                        <Col sm={{ size: 'auto', offset: 0 }}>
                            {/*PUBLICADO*/}
                            {/*questionarios[i]._id*/}
                            <CopyToClipboard  text={questionarios[i].code}>
                              <Button color="primary" href="" onClick={e => this.CopyCode()} size="sm">
                              Código = {questionarios[i].code}      
                              </Button>
                            </CopyToClipboard>
                        </Col>
                        <Col sm={{ size: 'auto', offset: 0 }}>
                          <Button color="danger" href="" onClick={e=> this.publishQuestionnaire(questionarios[i]._id,false)} size="sm">
                            DESPUBLICAR
                          </Button>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Button color="primary" href="" onClick={e => this.goEdit(e,questionarios[i]._id, questionarios[i].name)} size="sm">
                          Editar
                        </Button>
                      </Row>                           */}
                    </Container>
                  )
                  //else
                  :
                  (
                  <Container>
                    <Row>
                      <Col>
                          NÃO PUBLICADO
                      </Col>
                      <Col>
                        <Button color="success" href="" onClick={e=> this.publishQuestionnaire(questionarios[i]._id,true)} size="sm">
                          PUBLICAR
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                    <hr className="my-2" />
                    </Row>
                      <Row>
                        <Col>
                          <Button color="primary" href="" onClick={e => this.goEdit(e,questionarios[i]._id, questionarios[i].name)} size="sm">
                            Editar
                          </Button>
                        </Col>
                        <Col>
                          <Button color="danger" href="" onClick={e=> this.archiveQuestionnaire(questionarios[i]._id)} size="sm">
                              <span className="btn-inner--icon">
                              <i className="far fa-folder-open"></i>
                              </span>
                              ARQUIVAR
                          </Button>
                        </Col>
                      </Row>    
                  </Container>
                  )}
                  </CardFooter>
                  
                      {/*<div className='custom-control custom-switch'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          id='customSwitches'
                          checked={questionarios[i].availeble}
                          onChange={this.handleSwitchChange(1)}
                          readOnly
                        />
                        <label className='custom-control-label' htmlFor='customSwitches'>
                          Publicar?
                        </label>
                      </div>*/}
                </CardBody>
              </Card>
          </div>

                      
      )
    }
    
    // if(lista.length<1&&(!haveone)){
    //   lista.push(
    //     <Spinner color="info" />
    //   );
    // }
    return lista
  }

  
  getHtmlTooltips(){
    let saida = 
    <div>
      <UncontrolledTooltip
        delay={0}
        placement="bottom"
        target="BTNAddQuestionnaire"
      >
        Adiciona um Novo Questionário
      </UncontrolledTooltip>
    </div>
    return saida;
  }



  render() {
    const tooltips = this.getHtmlTooltips();
    return (
      <>
        <Header />
        {/* Page content */}
        <div>
          {tooltips}
          <Container className=" mt--7" fluid>
            {/* Table */}
            <Row>
              <Col>
                <Jumbotron className="bg-gradient-default" fluid >
                  <Container fluid>
                    <h1 className="display-3 text-white mb-3">Questionários</h1>
                    <p className="lead text-white mb-3">Nessa página você visualiza todos os questionários aos quais você criou ou está inscrito como editor.
                    </p>
                    <Button id="BTNAddQuestionnaire" className="mr-4" color="info" href="" onClick = {this.goCreate.bind(this)} size="sm">
                      Adicionar Questionário
                    </Button>
                  </Container>
                </Jumbotron>
                
                <div>
                  
                <UncontrolledAlert color="warning" fade={false}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Aviso!</strong> Para que um questionário possa ser publicado é necessário ter no mínimo 6 questões de cada dificuldade (fácil, médio e difícil), totalizando o mínimo 18 questões. (Mudanças virão em versões futuras)
                    </span>
                  </UncontrolledAlert>
                <UncontrolledAlert color="warning" fade={false}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Aviso!</strong> Um questionário só pode ser editado quando não está publicado.
                    </span>
                  </UncontrolledAlert>

                  {/* <UncontrolledAlert color="warning" fade={false}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Aviso!</strong> Não existe um máximo de questões, mas dentro do jogo serão selecionadas aleatoriamente 6 questões de cada dificuldade.
                    </span>
                  </UncontrolledAlert> */}
                  
                </div>
              </Col>
            </Row>
            <Row>
              {this.getHtmlQuestionarios()}
            </Row>
            
          </Container>




        </div>
      </>
    );
  }
}

export default Questionnaires;
