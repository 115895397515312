/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  //Media,
  Button,
  //Card,
  //CardHeader,
  //CardBody,
  // NavItem,
  // NavLink,
  // Nav,
  // Progress,
  // Table,
  Jumbotron,
  Container,
  Row,
  Col
} from "reactstrap";


import Header from "components/Headers/IndexHeader.js";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {    };
  }
  
  render() {
    
    const custombackground = {
      //fontSize: "5rem",
      //lineHeight: "4.5rem",
      //letterSpacing: "-0.1rem",
      
      //color: "#ffffff",
      //backgroundImage:require("assets/img/brand/gamequiz material-20.png")
      backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg)",
      //backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%),url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg)"
    };
  
    return (
      <>
        {<Header></Header>}
        <div className="main-content">
        {/* Page content */}
        
    <div>
      <Jumbotron className="banner">
        <h1  className="display-1 text-white">GameQuiz!</h1>
        <h2 className="display-2 text-white">Crie aulas interativas!</h2>
        <hr className="my-2" />
        <p className="text-white">Você pode criar jogos divertidos em formato de quiz e enviar para seus alunos jogarem em seus celulares.</p>
            
        <Row>
                  <Col sm="12" md={{ size: 6, offset: 5 }}>
                      <Button  className="mr-4" color="info" 
                      to="/auth/login"
                      tag={Link} size="lg">
                          Começar
                      </Button>
                  </Col>
              </Row>
      </Jumbotron>
      <Jumbotron >
          <Container  >
            <Row>
              {/*
              <Col><img object src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/parceiros/logo_unasus.png" alt="" /></Col>
              <Col><img object src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/parceiros/logo_ufma.png" alt="" /></Col>
              <Col><img object src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/parceiros/grupo-saite.png" alt="" /></Col>
              <Col><img object src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/parceiros/fousp-saite.png" alt="" /></Col>
              <Col><img object src="https://saitebooker.com.br/wp-content/uploads/2019/12/logo_fapema.png" alt="" /></Col>
              */}
              <Col ><img width="50%"  src={require("assets/img/brand/reguas-de-marcas-UNA-SUS_unasusufma.png")} alt=""   /></Col>
              
              <Col ><img width="50%"  src={require("assets/img/brand/reguas-de-marcas-UNA-SUS_gruposaite.png")} alt=""   /></Col>
              
              <Col ><img width="90%"  src={require("assets/img/brand/reguas-de-marcas-UNA-SUS_dted.png")} alt=""   /></Col>
              
            </Row>
          </Container>
          
      </Jumbotron>
              {/*<Col  sm="12" md={{ offset: 2 }}><img width="30%" object src={require("assets/img/brand/reguas-de-marcas-UNA-SUS_dted.png")} alt="" thumbnail  /></Col>*/}
              
      <Jumbotron >
      <Container  >
            <Row>
              <Col>
                <div className="">
                  <div className="d-flex">
                    <img  width="30%"  alt="..." src={require("assets/img/brand/gamequiz material-23.png")} />
                  
                  </div>
                  <h3>Aulas mais dinâmicas</h3>
                  <p className="">Torne sua aula mais interativa usando jogos em formato de quiz e tendo feedback dos alunos.</p>
                </div>
              </Col>
              <Col>
                <div className="">
                  <div className="d-flex">
                    <img width="40%"  alt="..." src={require("assets/img/brand/gamequiz material-24.png")} />
                  
                    </div>
                  <h3>Ferramenta totalmente gratuita</h3>
                  <p className="">Para ter acesso aos recursos e criar o seu quiz, basta preencher um formulário simples.</p>
                </div>
              </Col>
              <Col>
                <div className="">
                  <div className="d-flex">
                    <img width="30%"  alt="..." className="" src={require("assets/img/brand/gamequiz material-22.png")} />
                  
                    
                  </div>
                  <h3>Facilidade de uso</h3><p className="">De maneira fácil e rápida, a ferramenta proporciona autonomia na produção de novos quizzes.</p>
                </div>
              </Col>
            </Row>
          </Container>
      </Jumbotron>
      
      <Jumbotron style={custombackground} className="text-white">
          <Container >
            <Row>
              <Col>

              <div className="">
                <img className="" src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/icons/user.png" alt="foto do usuário"/>
                <h5>José Tavares Bezerra Jr</h5>
                <p><i>Professor</i></p>
                <p className="">Inovador e Criativo! O GameQuiz será bastante útil no ensino remoto. Parabéns aos idealizadores!</p>
              </div>
              </Col>
              <Col>
            <div className="">
              <img className="" src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/icons/user.png" alt="foto do usuário"/>
              <h5>Seu nome aqui</h5>
              <p><i>Profissão</i></p>
              <p className="">Entre em contato! Mande sua opinião sobre o GameQuiz para publicarmos aqui.</p>
            </div>
              </Col>
              <Col>
            <div className="">
              <img className="" src="https://saitebooker.com.br/wp-content/themes/saitebooker/images/icons/user.png" alt="foto do usuário"/>
              <h5>Seu nome aqui</h5>
              <p><i>Profissão</i></p>
              <p className="">Entre em contato! Mande sua opinião sobre o GameQuiz para publicarmos aqui.</p>
            </div>
              </Col>
            </Row>
          </Container>
      </Jumbotron>
    </div>
        
        </div>
        </>
    );
  }
}

export default Index;
