/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import IndexExample from "views/examples/IndexExample.js";
import Home from "views/examples/Index.js";
import Questionnaires from "views/examples/Questionnaires.js";
import Archived from "views/examples/Archived.js";
import Trash from "views/examples/Trash.js";
import CodeQuestionnaires from "views/examples/CodeQuestionnaires.js";
import ListQuestions from "views/examples/ListQuestions.js";
import ListUsers from "views/examples/ListUsers.js";
import EditQuestion from "views/examples/EditQuestion.js";
import RegisterQuestion from "views/examples/RegisterQuestion.js";
import RegisterQuestionnaire from "views/examples/RegisterQuestionnaire.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
// import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Index",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/auth",
    type:"Dash"
  },
  {
    path: "/inicio",
    name: "Início",
    icon: "ni ni-tv-2 text-primary",
    component: Home,
    layout: "/admin",
    type:"Dash"
  },
  {
    path: "/questionnaires",
    name: "Meus Questionários",
    icon: "ni ni-bullet-list-67 text-red",
    component: Questionnaires,
    layout: "/admin",
    type:"Dash"
  },
  {
    path: "/archived",
    name: "Arquivo",
    icon: "ni ni-folder-17 text-yellow",
    component: Archived,
    layout: "/admin",
    type:"Dash"
  },
  {
    path: "/trash",
    name: "Lixeira",
    icon: "far fa-trash-alt text-blue",
    component: Trash,
    layout: "/admin",
    type:"Dash"
  },
  
  {
    path: "/codeQuestionnaires",
    name: "Código dos questionários",
    icon: "ni ni-bullet-list-67 text-red",
    component: CodeQuestionnaires,
    layout: "/auth",
    type:"Dash"
  },
  {
    path: "/listQuestions",
    name: "Lista de Questões",
    icon: "ni ni-bullet-list-67 text-red",
    component: ListQuestions,
    layout: "/admin",
    type:"Edit"
  },
  {
    path: "/listUsers",
    name: "Lista de Usuários",
    icon: "ni ni-bullet-list-67 text-red",
    component: ListUsers,
    layout: "/admin",
    type:"Edit"
  },
  {
    path: "/editQuestion",
    name: "Editar Questão",
    icon: "ni ni-bullet-list-67 text-red",
    component: EditQuestion,
    layout: "/admin",
    type:"Edit"
  },
  {
    path: "/registerQuestion",
    name: "Registrar Questão",
    icon: "ni ni-bullet-list-67 text-red",
    component: RegisterQuestion,
    layout: "/admin",
    type:"Edit"
  },
  {
    path: "/registerQuestionnaire",
    name: "Registrar Questionário",
    icon: "ni ni-bullet-list-67 text-red",
    component: RegisterQuestionnaire,
    layout: "/admin",
    type:"Edit"
  },
  {
    path: "/indexExample",
    name: "Dashboard Example",
    icon: "ni ni-tv-2 text-primary",
    component: IndexExample,
    layout: "/admin",
    type:"Example"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    type:"Example"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
    type:"Example"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    type:"Example"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    type:"Example"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    type:"Dash"
  },/*
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    type:"Dash"
  }*/
];
export default routes;
