/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

  bg-gradient-default header bg-gradient-info pb-8 pt-5 pt-md-8
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Link } from "react-router-dom";

// reactstrap components
// import {  
//   Container,
//   Jumbotron, 
//   Row, 
//   Button, 
//   Col 
// } from "reactstrap";

class IndexHeader extends React.Component {
  render() {
      
    // const h1 = {
    //     fontSize: "5rem",
    //     lineHeight: "4.5rem",
    //     letterSpacing: "-0.1rem",
    //     backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg) !important;"

    //   };
      
    // const custombackground = {
      
    //   backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg)",
    //   //backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%),url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg)"
    // };
    return (
      <>
      
    {/*<div className="header showcase pb-8 pt-5 pt-md-8">*/}
    {/*<div className="header bg-gradient-index pb-8 pt-8 pt-md-8">*/}
        
        <div className="header bg-gradient-default pb-1 pt-5 pt-md-5">
                  
        </div>
        
      </>
    );
  }
}

export default IndexHeader;
