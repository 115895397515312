/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";


import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  //Card,
  //CardTitle,
  //CardText,
  Container,
  Jumbotron,
  Row,
  Col
} from "reactstrap";


import Header from "components/Headers/Header.js";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }
  render() {
    // const custombackground = {
    //   backgroundImage: "linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg)",
    // };
    return (
      <>
        <Header />
        {/* Page content */}
        <div>
        <div className="main-content">
          <Container className=" mt--7" fluid>
            <Jumbotron className="banner">
                  <Container fluid>
                    <h1 className="display-3 text-white mb-3">Bem vindo(a)!</h1>
                    <p className="lead text-white mb-3">
                    Esta é a ferramenta de criação de Quiz para o GameQuiz 2.0
                    </p>
                    <p className="lead text-white mb-3">
                    Mais informações em breve.
                    </p>
                    
                    <Row>
                        <Col>
                            <Button className="mr-4" color="info" 
                      to="/admin/questionnaires"
                      tag={Link} size="lg">Começar</Button>
                        </Col>
                    </Row>
                  </Container>
      </Jumbotron>
            
        {/* <Jumbotron className="bg-gradient-default" fluid >
                  <Container fluid>
                    <h1 className="display-3 text-white mb-3">Bem vindo(a)!</h1>
                    <p className="lead text-white mb-3">
                    Esta é a ferramenta de criação de Quiz para o GameQuiz 2.0
                    </p>
                    <p className="lead text-white mb-3">
                    Mais informações em breve.
                    </p>
                    
                    <Row>
                        <Col>
                            <Button className="mr-4" color="info" 
                      to="/admin/questionnaires"
                      tag={Link} size="lg">Começar</Button>
                        </Col>
                    </Row>
                  </Container>
                </Jumbotron> */}
            {/* <Row className = "col-lg-10" style={{ padding: '15px' }}>
                <Card body inverse style={{ backgroundColor: '#1F4963', borderColor: '#333' }}>
                    <CardTitle>Tutorial</CardTitle>
                    <CardText>EM BREVE.</CardText>
                    <Row>
                        <Col>
                            <Button color="primary" size="sm" disabled>Começar</Button>
                        </Col>
                    </Row>
                </Card>
            </Row> */}
            {/* <Row className = "col-lg-10" style={{ padding: '15px' }}>
                <Card body inverse style={{ backgroundColor: '#1F4963', borderColor: '#333' }}>
                    <CardTitle>Dados dos Questionários</CardTitle>
                    <CardText>EM BREVE.</CardText>
                    <Row>
                        <Col>
                            <Button color="primary" size="sm" disabled>Entrar</Button>
                        </Col>
                    </Row>
                </Card>
            </Row> */}
            {/* <Row className = "col-lg-10" style={{ padding: '15px' }}>
                <Card body inverse style={{ backgroundColor: '#1F4963', borderColor: '#333' }}>
                    <CardTitle>Dúvidas Frequentes</CardTitle>
                    <CardText>EM BREVE.</CardText>
                    <Row>
                        <Col>
                            <Button color="primary" size="sm" disabled>Entrar</Button>
                        </Col>
                    </Row>
                </Card>
            </Row> */}
        </Container>
        </div>
        </div>
      </>
    );
  }
}

export default Index;
