/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import api from 'services/Api';

import {CopyToClipboard} from 'react-copy-to-clipboard';
// react component that copies the given text inside your clipboard
//import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Button,
  Card, 
  CardText, 
  //CardHeader,
  CardBody,
  CardTitle,
  CardFooter, 
  Container,
  Row,
} from "reactstrap";
// core components
//import Header from "components/Headers/Header.js";

class CodeQuestionnaires extends React.Component {
  state = {};
  constructor(){

    super()
    this.state = {
      questionarios : []
    }
    
  }
  


  componentDidMount(){

    api.get('api/CodigosTemas')
      .then(res => {
        
        const questionarios = res.data
        
        this.setState({questionarios: questionarios.questionnaires})
        
      })
      .catch(err => {
        alert("Erro ao carregar questionários");
        //console.log("err");
        //console.log(err);
      })
  }
  

  CopyCode(){
    alert("Código copiado com sucesso")
  } 

  getHtmlQuestionarios(){
    const questionarios = this.state.questionarios;
    //const jsonName = this.state.jsonName;
    let lista = [];
    for(let i in questionarios){
      //console.log(questionarios[i]);
      lista.push(
        <div className = "col-lg-6" style={{ padding: '15px' }}  key = {questionarios[i]._id}>
           
              <Card color="secondary">
                <CardBody>
                  <CardTitle>{questionarios[i].name}</CardTitle>
                  <CardText>{questionarios[i].information}</CardText>
                  
                      
                </CardBody>
                <CardFooter className="">
                  <CardText>Código = {questionarios[i].code}</CardText>
                  <CopyToClipboard  text={questionarios[i].code}>
                    <Button color="primary" href="" onClick={e => this.CopyCode()} size="sm">
                      Copy Code      
                    </Button>
                  </CopyToClipboard>
                </CardFooter>
              </Card>
          </div>

                      
      )
    }
    if(questionarios.length<1){
      //se nao existem elementos
      lista.push(
        <div className = "col-lg-6" style={{ padding: '15px' }}>
           
              <Card color="secondary">
                <CardBody>
                  <CardTitle>Erro ao carregar conteúdo</CardTitle>
                  <CardText>Porfavor contactar o administrador.</CardText>                
                      
                </CardBody>
              </Card>
          </div>

                      
      )
    }
    return lista
  }



  render() {
    return (
      <>
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          
          <Row>
          <div className=" col">
              <div  className="shadow">
                <div className="bg-transparent">
                    <div className="text-left">
                        <h1 className="text-white mb-3">Questionários</h1>
                    </div>
                </div>
                <div>
                    {/*<Button  className="mr-4" color="info" href="" onClick = {this.goCreate.bind(this)} size="sm">
                      Adicionar Questionário
    </Button>*/}  
                  <Row className=" icon-examples">
                      <div className = "card-body"> 
                        <div className = "row mt-2">
                          
                            {this.getHtmlQuestionarios()}
                          
                        </div>
                      </div>
                  </Row>
                </div>
              </div>

            </div>
          </Row>
          
        </Container>
      </>
    );
  }
}

export default CodeQuestionnaires;
