import React from "react";
//import styled from "styled-components";
import {
  Input,
  Badge,
  //FormGroup,
} from "reactstrap";

class CharCountInput extends React.Component {
  state = {
    charsLeft: null,
    fewChars: false,
    minChars: 0
  };

  componentDidMount() {
    this.handleCharCount(this.props.value);
  }

  handleCharCount = value => {
    const { maxLength, minLength } = this.props;
    const charCount = value.length;
    const charsLeft = maxLength - charCount;
    (charCount <= minLength) ? this.setState({fewChars: true }) : this.setState({fewChars: false  }) ;
    this.setState({ minChars: minLength});
    this.setState({ charsLeft: charsLeft });
  };

  handleChange = event => {
    this.handleCharCount(event.target.value);
    this.props.onChange(event);
  };

  renderCharactersLeft = () => {
    const { charsLeft, fewChars, minChars } = this.state;
    let content;
    if (charsLeft >= 0 && fewChars === false ) {
      //content = <SpanOK>{`caracteres restantes: ${charsLeft}`}</SpanOK>;
      content = <Badge className="float-right" color="success" >
        {`caracteres restantes: ${charsLeft}`}
      </Badge>
    } else if (charsLeft != null && charsLeft < 0  ) {
      const string = charsLeft.toString().substring(1);
      //content = <SpanError>{`muitos caracteres: ${string} caracteres a mais`}</SpanError>;
      content = <Badge className="float-right" color="danger" >
        {`muitos caracteres: ${string} caracteres a mais`}
      </Badge>
    } else if ( fewChars === true ) {
      const string = minChars ; 
      content = <Badge className="float-right" color="info" >
                    {`limite mínino de caracteres: ${string}`}
                </Badge>
    }
    else
    {
      content = null;
    }
    return content;
  };

  render() {
    const { className,/*onBlur,*/ id, maxLength, value, type, name, placeholder, rows } = this.props;

    return (
      <>
        <Input
          className={className}
          id={id}
          onChange={this.handleChange}
          value={value}
          type={type}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={rows}
        />
        {this.renderCharactersLeft()}
      </>
    );
  }
}

export default CharCountInput;

// const Div = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const CustomInput = styled.input`
//   box-sizing: border-box;
//   display: block;
//   padding: 7px;
//   margin: 0 0 0.1rem 0;
//   border: 1px solid blue;
//   border-radius: 7px;
//   font: inherit;
//   outline: none;

//   &:focus {
//     box-shadow: 0 0 4px blue;
//   }
// `;

// const Span = styled.span`
//   align-self: flex-end;
//   font-size: 0.9rem;
//   margin: 0 8px 10px 0;
// `;
